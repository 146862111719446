import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import "twin.macro";

import { useWindowWidth } from "@helpers";
import { useSeo } from "@queries";

import { Layout } from "@components/layout";
import { Row, Heading, Textbox, Socials } from "@components/shared";
import { Map } from "@components/contactpage";

const ContactInfo = ({ icon: Icon, text }) => (
  <div tw="flex items-center to-lg:justify-center to-sm:flex-col text-2xl text-primary to-sm:mb-10 mb-2">
    <Icon tw="to-sm:mb-2 sm:mr-3" />
    <span tw="break-all">{text}</span>
  </div>
);

const ContactPage = ({ data }) => {
  const windowWidth = useWindowWidth();

  const { description_contact } = useSeo();
  const { email, phone_number, _rawText } = data.sanityContactPage;

  return (
    <Layout showNewsletter title="Kontakt" description={description_contact}>
      <Heading as="h1" tw="text-center mb-12">
        Kontakt
      </Heading>
      <Row>
        <Row.Col tw="flex flex-col justify-between to-lg:items-center px-8 xl:px-16">
          <div>
            <ContactInfo icon={FaPhone} text={phone_number} />
            <ContactInfo icon={FaEnvelope} text={email} />
            <Textbox blocks={_rawText} tw="mt-10" center={windowWidth < 1024} />
          </div>
          <div tw="text-typo-light mt-12">
            <span>Znajdziesz nas na:</span>
            <Socials tw="mt-2" />
          </div>
        </Row.Col>
        <Row.Col tw="h-120 to-lg:mt-12">
          <Map />
        </Row.Col>
      </Row>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    sanityContactPage: PropTypes.shape({
      email: PropTypes.string.isRequired,
      phone_number: PropTypes.string.isRequired,
      _rawText: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query ContactPageQuery {
    sanityContactPage {
      email
      phone_number
      _rawText
    }
  }
`;

export default ContactPage;
