import React from "react";
import { Map as LeafletMap, TileLayer } from "react-leaflet";

import "twin.macro";

const Map = () => (
  <LeafletMap
    center={[53.86404302, 20.95884293]}
    zoom={20}
    scrollWheelZoom={false}
    tw="w-full h-full shadow-inner z-10 to-lg:pointer-events-none"
  >
    <TileLayer
      url={`https://api.mapbox.com/styles/v1/kulinarnieinaczej/ckd1on4rs0wb01impm22ebdno/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.GATSBY_MAPBOX_KEY}`}
      attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
    />
  </LeafletMap>
);

export default Map;
